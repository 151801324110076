.App {
  text-align: center;
  background: linear-gradient(rgb(86, 86, 114), rgb(40, 42, 62));
}

.apod-card {
  max-width: 36rem;
}

.nasaAPOD-img {
  object-fit: contain;
}

.apod-copyright {
  width: 100%;
}

.react-app-footer {
  height: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
